import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private transloco: TranslocoService) {
    super();
    this.itemsPerPageLabel = this.transloco.translate('items.per.page');
    this.nextPageLabel = this.transloco.translate('next');
    this.previousPageLabel = this.transloco.translate('previous');
    this.lastPageLabel = this.transloco.translate('last');
    this.firstPageLabel = this.transloco.translate('first');
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };
}
