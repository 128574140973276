<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.admin"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <div class="cursor-pointer flex justify-center items-center flex-col" routerLink="/" fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <!--    <div class="flex items-center h-40 px-20 pt-10">-->
    <!--      <img class="w-30" src="assets/images/logo/Emblem_of_Uzbekistan.svg" alt="Logo image" />-->
    <!--    </div>-->
    <div class="w-44 mt-4">
      <div>
        <img src="assets/icons/logo-original.svg" alt="LOGO" />
      </div>
    </div>
    <div class="text-center pb-5 text-sm text-slate-500 mt-3">{{ 'administrator' | transloco }}</div>
  </div>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full py-1 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <search [appearance]="'bar'"></search> -->
      <!-- <shortcuts></shortcuts> -->
      <!-- <messages></messages> -->
<!--      <notifications></notifications>-->
      <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!--    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
  <!--        <span class="font-medium text-secondary">Vazirlar Mahkamasi &copy; {{currentYear}}</span>-->
  <!--    </div>-->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
