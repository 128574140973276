<div class="flex items-center gap-2 hover:bg-hover cursor-pointer p-2 rounded-lg" [matMenuTriggerFor]="userActions">
  <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  <span>{{ user?.username }}</span>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <!--    <button-->
  <!--        mat-menu-item-->
  <!--        (click)="changePasswordDialog()">-->
  <!--        <mat-icon [svgIcon]="'heroicons_outline:finger-print'"></mat-icon>-->
  <!--        <span>{{'change.password' | transloco}}</span>-->
  <!--    </button>-->

  <!--    <mat-divider></mat-divider>-->

  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>{{ 'sign.out' | transloco }}</span>
  </button>
</mat-menu>
