import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { httpConfigInterceptor } from './http-config.interceptor';
import { httpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { httpAuthInterceptor } from '@core/auth/auth.interceptor';

export const provideHttp = (): Array<Provider | EnvironmentProviders> => {
  return [
    provideHttpClient(withInterceptors([httpErrorInterceptor, httpConfigInterceptor, httpAuthInterceptor])),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(AuthService),
      multi: true,
    },
  ];
};
