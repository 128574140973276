import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'alert-message',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './alert-message.component.html',
})
export class AlertMessageComponent {
  data: { message: string; error: boolean } = inject(MAT_SNACK_BAR_DATA);
}
