import { Constants } from '@shared/constants/constants';
import { YaConfig } from 'angular8-yandex-maps';

const DEFULT_Y_MAP_INDEX = '0';
export const Y_MAP_API_KEYS = [
  // {
  //   apikey: '5a6b2ef3-3f8d-45cc-a028-aeb7b3386a68',
  //   suggest_apikey: 'fe8817f4-e5b0-42c0-a182-3c5f5ae49e10',
  //   yandex_account: 'odilbek.utamuratov',
  // },
  {
    apikey: 'e96b27c2-1675-4eee-b62d-e8658ea3e440',
    yandex_account: 'amirov4maps',
  }
];

export function provideYandexMapsConfig(): YaConfig {
  let yandexMapApiKeyIndex = localStorage.getItem(Constants.LOCAL_STORAGE_KEYS.yandexMapApiKeyIndex);
  if (!yandexMapApiKeyIndex) {
    yandexMapApiKeyIndex = DEFULT_Y_MAP_INDEX;
    localStorage.setItem(Constants.LOCAL_STORAGE_KEYS.yandexMapApiKeyIndex, yandexMapApiKeyIndex);
  }

  return {
    apikey: Y_MAP_API_KEYS[+yandexMapApiKeyIndex].apikey || Y_MAP_API_KEYS[0].apikey,
    // suggest_apikey: Y_MAP_API_KEYS[+yandexMapApiKeyIndex].suggest_apikey || Y_MAP_API_KEYS[0].suggest_apikey,
    lang: 'ru_RU',
  } as YaConfig;
}
