export enum UserRole {
  ADMIN = 'administrator',
  DISPATCHER = 'dispatcher',
  PROVIDER_USER = 'provider_user',
  OPERATOR = 'operator',
  OPERATOR_ADMIN = 'operator_admin',
  CALLBACK_USER = 'callback',
}

export enum PermissionsEnum {
  ADMIN = 'ADMIN',
  GUIDES = 'GUIDES',
  INCIDENTS_CREATE = 'INCIDENTS_CREATE',
  INCIDENTS_VIEW = 'INCIDENTS_VIEW',
  RESOURCES = 'RESOURCES'
}
