<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false">
    <!-- Navigation header hook -->
    <div class="cursor-pointer" routerLink="/" fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex flex-col justify-center items-center h-40">
        <img class="w-24" [src]="logoSrc" alt="Logo image" />
      </div>
    </div>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col items-center w-full min-w-0 h-screen bg-gray-200 dark:bg-card">
  <!-- Header -->
  <div
    class="relative flex flex-col flex-0 justify-center w-full overflow-hidden z-49 shadow dark:shadow-none print:hidden">
    <!-- Top bar -->
    <div class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 py-1 bg-gray-800 dark:bg-gray-900">
      <div class="flex justify-between items-center w-full">
        <div *ngIf="!isScreenSmall" class="flex items-center gap-2 cursor-pointer" routerLink="/">
          <img class="w-[186px]" [src]="logoSrc" alt="Logo image" />
          <!-- <div class="text-4xl p-0">{{ '112' | transloco }}</div> -->
        </div>

        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>
        </ng-container>
        <!-- Components -->
        <!-- Bottom bar -->
        <ng-container *ngIf="!isScreenSmall">
          <div class="flex flex-auto justify-between items-center">
            <div class="flex flex-grow items-center justify-center">
              <fuse-horizontal-navigation
                class="-mx-4"
                [name]="'mainNavigation'"
                [navigation]="navigation.default"></fuse-horizontal-navigation>
            </div>
            <button class="flex gap-2 rounded-xl bg-blue-500 text-white items-center px-3 py-2" (click)="onCreate()">
              <mat-icon class="text-white" [svgIcon]="'mat_outline:add'"></mat-icon>
              <span>
                {{'create.new.incident' | transloco}}
              </span>
            </button>
          </div>
        </ng-container>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
          <languages></languages>
          <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
          <!--          <search [appearance]="'bar'"></search>-->
          <!--          <shortcuts></shortcuts>-->
          <!--          <messages></messages>-->
<!--          <notifications></notifications>-->
          <!--          <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">-->
          <!--            <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>-->
          <!--          </button>-->
          <user></user>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-auto justify-center w-full overflow-y-auto" (scroll)="scroll($event)">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!--  <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">-->
  <!--    <div class="flex items-center w-full h-14 sm:h-20">-->
  <!--      <span class="font-medium text-secondary">Fuse &copy; {{ currentYear }}</span>-->
  <!--    </div>-->
  <!--  </div>-->
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
