export class Constants {
  public static readonly LOCAL_STORAGE_KEYS = {
    yandexMapApiKeyIndex: 'yandexMapApiKeyIndex',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    sessionId: 'sessionId'
  };
  public static readonly RELATIONSHIP_TYPE_HIMSELF = {
    name_uzl: "O'zi",
  };
}
