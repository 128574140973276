import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Pipe({
  name: 'name',
  standalone: true,
  pure: true,
})
export class NamePipe implements PipeTransform {
  activeLang: string;
  count = 0;
  constructor(private translocoService: TranslocoService) {
    this.activeLang = this.translocoService.getActiveLang();
    this.translocoService.langChanges$.pipe(takeUntilDestroyed()).subscribe(lang => {
      this.activeLang = lang;
    });
  }
  transform(value: any, namesProp = 'names', fallBackProp = 'name'): string {
    if (typeof value !== 'object') return value;

    const langs = this.translocoService.getAvailableLangs().map(lang => lang.id);
    const names = value?.[namesProp];
    const nameFromNames =
      names?.[this.activeLang] ||
      names?.[langs[0]] ||
      names?.[langs[1]] ||
      names?.[langs[2]];

    if (nameFromNames) return nameFromNames;

    const nameFromValue =
      value?.['name_' + this.activeLang] ||
      value?.['name_' + langs[0]] ||
      value?.['name_' + langs[1]] ||
      value?.['name_' + langs[2]];

    if (nameFromValue) return nameFromValue;


    return value?.[fallBackProp] || names?.[fallBackProp] || '';
  }
}
