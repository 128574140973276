/* eslint-disable */
import {DateTime} from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const notifications = [
  {
    id: 'b91ccb58-b06c-413b-b389-87010e03a120',
    icon: 'heroicons_mini:envelope',
    title: '101',
    description: 'ДТП в Юнусабаде.',
    time: now.minus({ hour: 3 }).toISO(), // 3 hours ago
    read: false,
    link: '/dashboards/project',
    useRouter: true,
  },
  {
    id: 'b91ccb58-b06c-413b-b389-87010e03a121',
    icon: 'heroicons_mini:envelope',
    title: '102',
    description: 'ДТП в Юнусабаде.',
    time: now.minus({ hour: 3 }).toISO(), // 3 hours ago
    read: false,
    link: '/dashboards/project',
    useRouter: true,
  },
  {
    id: 'b91ccb58-b06c-413b-b389-87010e03a122',
    icon: 'heroicons_mini:envelope',
    title: '103',
    description: 'ДТП в Юнусабаде.',
    time: now.minus({ hour: 3 }).toISO(), // 3 hours ago
    read: false,
    link: '/dashboards/project',
    useRouter: true,
  },
];
