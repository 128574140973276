import { FuseNavigationItem } from '@fuse/components/navigation';
import { PermissionsEnum } from '@shared/features/user/user-roles.interface';
import { ADMIN_NAVIGATION } from '@features/admin/constants/admin-navigation.constant';

export interface RoleFuseNavigationItem extends FuseNavigationItem {
  permissions?: PermissionsEnum[];
}

export const NAVIGATION_DATA: RoleFuseNavigationItem[] = [
  // {
  //   id: 'public',
  //   title: 'statistics',
  //   type: 'basic',
  //   icon: 'mat_outline:bar_chart',
  //   link: '/statistics',
  // },
  {
    id: 'public',
    title: 'incident.registry',
    type: 'basic',
    icon: 'mat_outline:list_alt',
    link: '/incident/incidents',
    permissions: [PermissionsEnum.INCIDENTS_VIEW]
  },
  {
    id: 'public',
    title: 'assign.brigade.at.work',
    type: 'basic',
    icon: 'heroicons_outline:truck',
    link: '/assign-brigade-at-work',
    permissions: [PermissionsEnum.RESOURCES]
  },
  ...ADMIN_NAVIGATION,
];
