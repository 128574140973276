import { mockApiServices } from '../../../@fuse/mock-api';
import { FuseProviderConfig } from '../../../@fuse';

export default {
  mockApi: {
    delay: 0,
    services: mockApiServices,
  },
  fuse: {
    layout: 'classic',
    scheme: 'light',
    screens: {
      sm: '600px',
      md: '960px',
      lg: '1280px',
      xl: '1440px',
    },
    theme: 'theme-default',
    themes: [
      {
        id: 'theme-default',
        name: 'Default',
      },
      {
        id: 'theme-brand',
        name: 'Brand',
      },
      {
        id: 'theme-teal',
        name: 'Teal',
      },
      {
        id: 'theme-rose',
        name: 'Rose',
      },
      {
        id: 'theme-purple',
        name: 'Purple',
      },
      {
        id: 'theme-amber',
        name: 'Amber',
      },
    ],
  },
} as FuseProviderConfig;
