import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { logger } from '@shared/utils/logger.util';
import { AlertService } from '@shared/services/alert.service';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const alertService = inject(AlertService);
  const start = Date.now();

  return next(req).pipe(
    catchError(error => {
      console.log('error ---->', { error });
      let message = error?.error?.message + (error?.error?.prop ? `prop: ${error?.error?.prop}` : '') || 'something.went.wrong';
      if (error instanceof HttpErrorResponse && error.status === 401) {
        authService.signOut();
        message = 'your.session.has.expired.please.sign.in.again';
      }
      logger('ERROR', start, req.url, req.body, error.error);
      alertService.showError(message);

      return throwError(() => error);
    })
  );
};
