import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IApiRes } from '@shared/interfaces/api-response.interface';
import { logger } from '@shared/utils/logger.util';

export const httpConfigInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const start = Date.now();
  const WHITE_LISTS = ['assets/i18n'];

  if (req.responseType !== 'json') {
    return next(req);
  }

  if (WHITE_LISTS.some(w => req.url.includes(w))) {
    return next(req);
  }

  return next(req).pipe(
    map((event: HttpEvent<IApiRes<any>>) => {
      if (event.type === HttpEventType.Response) {
        const body = event.body.response || event.body.ok || event.body;
        logger('API', start, req.url, req.body, body);
        return event.clone({
          body: body,
        });
      }

      return event;
    })
  );
};
