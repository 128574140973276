import { IAdminItemData } from '@features/admin/interfaces/admin-item-data.interface';

export enum AdminItem {
  USER = 'user',
  // directory
  ROLES = 'roles',
  REGIONS = 'regions',
  DISTRICTS = 'districts',
  AREAS = 'areas',
  NEIGHBORHOODS = 'neighborhoods',
  ORGANIZATIONS = 'organizations',
  BRANCHES = 'branches',
  BRIGADES = 'brigades',
  BRIGADES_USERS = 'brigades_users',
  RELATION_TYPE = 'relation-type',
  CALL_PLACE = 'call-place',
  PLACE_TYPE = 'place-types',
  LOCATION_TYPE = 'location-types',
  ORIGIN_TYPE = 'origin-types',
  FUEL_TYPE = 'fuel-types',
  TECHNIQUE_TYPE = 'technique-types',
  TECHNIQUES = 'TECHNIQUES'
  // LOCATION_TYPES = 'location-types',
}

export enum AdminEntities {
  ROLES_PERMISSIONS = 'role-permissions',
  ROLE_WITH_PERMISSIONS = 'role-with-permissions',
  ORGANIZATIONS_USERS = 'organizations-users',
  ORGANIZATIONS_USERS_BY_USER = 'organizations-users-by-user',
}

export const NAME_COLUMNS = [
  { title: 'in.latin', dataKey: 'names.uz' },
  { title: 'in.cyrillic', dataKey: 'names.uz_cyr' },
  { title: 'in.russian', dataKey: 'names.ru' },
];

export const LOCATION_COLUMNS = [
  { title: 'in.latin', dataKey: 'names.uz' },
  { title: 'in.russian', dataKey: 'names.ru' },
  { title: 'region', dataKey: 'region_id' },
  { title: 'district', dataKey: 'district_id' },
];

export const ADMIN_ITEMS_DATA: { [key in AdminItem]: IAdminItemData } = {
  [AdminItem.USER]: {
    id: AdminItem.USER,
    title: 'users',
    navigation: {
      id: 'admin',
      title: 'user',
      type: 'basic',
      icon: 'mat_outline:people',
      link: `/admin/dashboard/${AdminItem.USER}`,
    },
    hasActions: true,
    columns: [
      { title: 'last.name', dataKey: 'last_name' },
      { title: 'first.name', dataKey: 'first_name' },
      { title: 'username', dataKey: 'username' },
      { title: 'phone', dataKey: 'phone' },
      // { title: 'roles', dataKey: 'roles' },
    ],
  },
  [AdminItem.BRANCHES]: {
    id: AdminItem.BRANCHES,
    title: 'branches',
    navigation: {
      id: 'admin',
      title: 'branches',
      type: 'basic',
      icon: 'mat_outline:call_split',
      link: `/admin/dashboard/${AdminItem.BRANCHES}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.BRIGADES]: {
    id: AdminItem.BRIGADES,
    title: 'brigades',
    navigation: {
      id: 'admin',
      title: 'brigades',
      type: 'basic',
      icon: 'mat_outline:local_shipping',
      link: `/admin/dashboard/${AdminItem.BRIGADES}`,
    },
    hasActions: true,
    columns: [
      { title: 'code', dataKey: 'code' },
      { title: 'branch', dataKey: 'branch_id' },
      { title: 'brigadier', dataKey: 'brigadier' },
    ],
  },
  [AdminItem.BRIGADES_USERS]: {
    id: AdminItem.BRIGADES_USERS,
    title: 'brigades_users',
    navigation: {
      id: 'admin',
      title: 'brigades_users',
      type: 'basic',
      icon: 'mat_outline:people',
      link: `/admin/dashboard/${AdminItem.BRIGADES_USERS}`,
    },
    hasActions: true,
    columns: [
      { title: 'brigade.code', dataKey: 'brigade_id' },
      { title: 'user', dataKey: 'user_id' },
      { title: 'start.date', dataKey: 'start_date' },
      { title: 'end.date', dataKey: 'end_date' },
    ],
  },
  [AdminItem.TECHNIQUES]: {
    id: AdminItem.TECHNIQUES,
    title: 'techniques',
    navigation: {
      id: 'admin',
      title: 'techniques',
      type: 'basic',
      icon: 'mat_outline:precision_manufacturing',
      link: `/admin/dashboard/${AdminItem.TECHNIQUES}`,
    },
    hasActions: true,
    columns: [
      { title: 'name', dataKey: 'name' },
      { title: 'model', dataKey: 'model' },
      { title: 'state_number', dataKey: 'state_number' },
      { title: 'branch', dataKey: 'branch_id' },
      { title: 'status', dataKey: 'status' },
    ],
  },
  [AdminItem.ROLES]: {
    id: AdminItem.ROLES,
    title: 'roles',
    navigation: {
      id: 'admin',
      title: 'roles',
      type: 'basic',
      icon: 'mat_outline:vpn_key',
      link: `/admin/dashboard/${AdminItem.ROLES}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.REGIONS]: {
    id: AdminItem.REGIONS,
    title: 'regions',
    navigation: {
      id: 'admin',
      title: 'regions',
      type: 'basic',
      icon: 'mat_outline:map',
      link: `/admin/dashboard/${AdminItem.REGIONS}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.DISTRICTS]: {
    id: AdminItem.DISTRICTS,
    title: 'districts',
    navigation: {
      id: 'admin',
      title: 'districts',
      type: 'basic',
      icon: 'mat_outline:location_on',
      link: `/admin/dashboard/${AdminItem.DISTRICTS}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.AREAS]: {
    id: AdminItem.AREAS,
    title: 'areas',
    navigation: {
      id: 'admin',
      title: 'areas',
      type: 'basic',
      icon: 'mat_outline:my_location',
      link: `/admin/dashboard/${AdminItem.AREAS}`,
    },
    hasActions: true,
    columns: LOCATION_COLUMNS,
  },
  [AdminItem.NEIGHBORHOODS]: {
    id: AdminItem.NEIGHBORHOODS,
    title: 'neighborhoods',
    navigation: {
      id: 'admin',
      title: 'neighborhoods',
      type: 'basic',
      icon: 'mat_outline:apartment',
      link: `/admin/dashboard/${AdminItem.NEIGHBORHOODS}`,
    },
    hasActions: true,
    columns: LOCATION_COLUMNS,
  },
  [AdminItem.ORGANIZATIONS]: {
    id: AdminItem.ORGANIZATIONS,
    title: 'organizations',
    navigation: {
      id: 'admin',
      title: 'organizations',
      type: 'basic',
      icon: 'mat_outline:home_work',
      link: `/admin/dashboard/${AdminItem.ORGANIZATIONS}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.RELATION_TYPE]: {
    id: AdminItem.RELATION_TYPE,
    title: 'applicant.relation',
    navigation: {
      id: 'admin',
      title: 'applicant.relation',
      type: 'basic',
      icon: 'mat_outline:people_alt',
      link: `/admin/dashboard/${AdminItem.RELATION_TYPE}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.CALL_PLACE]: {
    id: AdminItem.CALL_PLACE,
    title: 'place',
    navigation: {
      id: 'admin',
      title: 'place',
      type: 'basic',
      icon: 'mat_outline:apartment',
      link: `/admin/dashboard/${AdminItem.CALL_PLACE}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.PLACE_TYPE]: {
    id: AdminItem.PLACE_TYPE,
    title: 'place.types',
    navigation: {
      id: 'admin',
      title: 'place.types',
      type: 'basic',
      icon: 'mat_outline:apartment',
      link: `/admin/dashboard/${AdminItem.PLACE_TYPE}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.LOCATION_TYPE]: {
    id: AdminItem.LOCATION_TYPE,
    title: 'location.types',
    navigation: {
      id: 'admin',
      title: 'location.types',
      type: 'basic',
      icon: 'mat_outline:place',
      link: `/admin/dashboard/${AdminItem.LOCATION_TYPE}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.ORIGIN_TYPE]: {
    id: AdminItem.ORIGIN_TYPE,
    title: 'origin.types',
    navigation: {
      id: 'admin',
      title: 'origin.types',
      type: 'basic',
      icon: 'mat_outline:apartment',
      link: `/admin/dashboard/${AdminItem.ORIGIN_TYPE}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.FUEL_TYPE]: {
    id: AdminItem.FUEL_TYPE,
    title: 'fuel.types',
    navigation: {
      id: 'admin',
      title: 'fuel.types',
      type: 'basic',
      icon: 'mat_outline:local_gas_station',
      link: `/admin/dashboard/${AdminItem.FUEL_TYPE}`,
    },
    hasActions: true,
    columns: NAME_COLUMNS,
  },
  [AdminItem.TECHNIQUE_TYPE]: {
    id: AdminItem.TECHNIQUE_TYPE,
    title: 'technique.types',
    navigation: {
      id: 'admin',
      title: 'technique.types',
      type: 'basic',
      icon: 'mat_outline:commute',
      link: `/admin/dashboard/${AdminItem.TECHNIQUE_TYPE}`,
    },
    hasActions: true,
    columns: [{ title: 'external', dataKey: 'external_id' },
      ...NAME_COLUMNS,
      ],
  },
};

export enum INCIDENT_TYPE_ENUM {
  SEWAGE = 'SEWAGE',
  WATER_SUPPLY = 'WATER_SUPPLY',
}

export const incident_types = [
  {
    id: INCIDENT_TYPE_ENUM.WATER_SUPPLY,
    label: INCIDENT_TYPE_ENUM.WATER_SUPPLY,
  },
  {
    id: INCIDENT_TYPE_ENUM.SEWAGE,
    label: INCIDENT_TYPE_ENUM.SEWAGE,
  },
];

export enum BRANCH_LEVEL_ENUM {
  DISTRICT = 'DISTRICT',
  REGION = 'REGION',
  REPUBLIC = 'REPUBLIC',
}

export const branch_levels = [
  {
    id: BRANCH_LEVEL_ENUM.REPUBLIC,
    label: 'republic',
  },
  {
    id: BRANCH_LEVEL_ENUM.REGION,
    label: 'region',
  },
  {
    id: BRANCH_LEVEL_ENUM.DISTRICT,
    label: 'district',
  },
];
