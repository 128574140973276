import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { AlertMessageComponent } from '@shared/components/alert-message/alert-message.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  transloco = inject(TranslocoService);

  config: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'top',
    horizontalPosition: 'center',
  };
  constructor(private snackbar: MatSnackBar) {}

  showSuccess(message: string): void {
    message = this.transloco.translate(message);
    this.snackbar.openFromComponent(AlertMessageComponent, {
      ...this.config,
      panelClass: 'success',
      data: {
        message,
      },
    });
  }

  showError(message: string): void {
    message = this.transloco.translate(message);
    this.snackbar.openFromComponent(AlertMessageComponent, {
      ...this.config,
      panelClass: 'error',
      data: {
        message,
        error: true,
      },
    });
  }
}
