import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable } from 'rxjs';

export const httpAuthInterceptor: HttpInterceptorFn = (req, next): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  let newReq = req.clone();

  // Now backed with working only sessionId
  if (authService.accessToken || authService.sessionId) {
    newReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authService.accessToken || authService.sessionId}`),
    });
  }

  return next(newReq);
};
