import { NgIf } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
  FuseHorizontalNavigationComponent,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { BehaviorSubject, forkJoin, Subject, takeUntil } from 'rxjs';
import { TranslocoModule } from '@ngneat/transloco';
import { PermissionsEnum, UserRole } from '@shared/features/user/user-roles.interface';
import { MatMenuModule } from '@angular/material/menu';
import { RequestsApiService } from '@features/requests/services/requests-api.service';
import { IRequestsCount, RequestStatusEnum } from '@features/requests/models/requests';
import { cloneDeep } from 'lodash-es';
import { IncidentService } from '@features/incident/services/incident.service';
import { INCIDENT_STATUSES } from '@features/incident/constants/card-status.constant';
import { RequestsService } from '@features/requests/services/requests.service';
import { IUser } from '@shared/features/user/user.interface';
import { UserService } from '@shared/features/user/user.service';

export const scrolled$ = new BehaviorSubject<Event | null>(null);

@Component({
  selector: 'enterprise-layout',
  templateUrl: './enterprise.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    NgIf,
    FuseVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    FuseHorizontalNavigationComponent,
    RouterOutlet,
    QuickChatComponent,
    TranslocoModule,
    MatMenuModule,
    RouterLink,
  ],
})
export class EnterpriseLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  roles = UserRole;
  user: IUser;
  readonly logoSrc = 'assets/icons/logo-original.svg';
  readonly permissions = PermissionsEnum;
  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private requestsApiService: RequestsApiService,
    private requestsService: RequestsService,
    private incidentsService: IncidentService,
    private _userService: UserService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.user = this._userService.user;
    // Subscribe to navigation data
    this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
      this.navigation = navigation;
      this.getRequestsCounts();
      this.getIncidentsCounts();
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
    this.listenForUpdates()
  }

  listenForUpdates() {
    this.requestsService.listenOnUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe((shouldUpdate) => {
      if (shouldUpdate) {
        this.getRequestsCounts();
      }
    });
    this.incidentsService.listenOnUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(shouldUpdate => {
      if (shouldUpdate) {
        this.getIncidentsCounts();
      }
    })
  }

  getRequestsCounts(){
    forkJoin({
      technique: this.requestsApiService.getRequestCount<IRequestsCount[]>('technique'),
      material: this.requestsApiService.getRequestCount<IRequestsCount[]>('material'),
    }).pipe(takeUntil(this._unsubscribeAll)).subscribe(responses => {
      const allCounts = (responses.technique?.find(count => count.status === RequestStatusEnum.PENDING)?.count || 0) +
        (responses.material.find(count => count.status === RequestStatusEnum.PENDING)?.count || 0);
      this.navigation = cloneDeep({...this.navigation,
        default: this.navigation.default.map(item => {
          if (item.id === 'requests') {
            item.badge = {
              title: allCounts.toString() || '0',
              classes: 'rounded-xl bg-orange-500 px-1.5',
            };
          }
          return item;
        }),
      })
    });
  }

  getIncidentsCounts(){
    this.incidentsService.getIncidentCount().subscribe(response => {
      let count = 0;
      response.forEach(item => {
        if ([INCIDENT_STATUSES.REGISTERED, INCIDENT_STATUSES.CREATED].includes(item.status)) {
          count += item.count;
        }
      })
      this.navigation = cloneDeep({...this.navigation,
        default: this.navigation.default.map(item => {
          if (item.id === 'incidents') {
            item.badge = {
              title: count.toString() || '0',
              classes: 'rounded-xl bg-orange-500 px-1.5',
            };
          }
          return item;
        }),
      })
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  @HostListener('scroll', ['$event'])
  scroll(e: Event) {
    scrolled$.next(e);
  }

  onCreate() {
    this._router.navigate(['incident', 'create'])
  }
}
