import { Injectable } from '@angular/core';
import { IUser } from '@shared/features/user/user.interface';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PermissionsEnum, UserRole } from '@shared/features/user/user-roles.interface';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user = new BehaviorSubject<IUser>(null);

  get user(): IUser {
    return this._user.value;
  }

  set user(value: IUser) {
    this._user.next(value);
  }

  get user$(): Observable<IUser> {
    return this._user.asObservable();
  }

  checkPermission(permissions: PermissionsEnum[]) {
    return this.user$.pipe(
      map(u => {
        return u?.permissions?.some(p => permissions.includes(p));
      })
    );
  }
}
