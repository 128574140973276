import { ADMIN_ITEMS_DATA, AdminItem } from '@features/admin/constants/admin-item.constant';
import { RoleFuseNavigationItem } from '@core/navigation/navigation-data';
import { PermissionsEnum } from '@shared/features/user/user-roles.interface';

export const ADMIN_NAVIGATION: RoleFuseNavigationItem[] = [
  {
    id: 'public',
    title: 'administrator',
    type: 'basic',
    icon: 'mat_outline:admin_panel_settings',
    link: `/admin/dashboard`,
    permissions: [PermissionsEnum.ADMIN, PermissionsEnum.GUIDES]
  },
  {
    id: 'admin',
    title: 'incident.registry',
    type: 'basic',
    icon: 'mat_outline:list_alt',
    link: '/incident/incidents',
    permissions: [PermissionsEnum.INCIDENTS_VIEW]
  },
  {
    id: 'admin',
    title: 'control.module',
    type: 'collapsable',
    icon: 'mat_outline:settings',
    permissions: [PermissionsEnum.ADMIN],
    children: [
      ADMIN_ITEMS_DATA[AdminItem.USER].navigation,
      ADMIN_ITEMS_DATA[AdminItem.ORGANIZATIONS].navigation,
      ADMIN_ITEMS_DATA[AdminItem.BRANCHES].navigation,
    ],
  },
  {
    id: 'admin',
    title: 'RESOURCES',
    type: 'collapsable',
    icon: 'heroicons_outline:folder-open',
    permissions: [PermissionsEnum.RESOURCES],
    children: [
      ADMIN_ITEMS_DATA[AdminItem.BRIGADES].navigation,
      ADMIN_ITEMS_DATA[AdminItem.BRIGADES_USERS].navigation,
      ADMIN_ITEMS_DATA[AdminItem.TECHNIQUES].navigation,
    ],
  },
  {
    id: 'admin',
    title: 'directory',
    type: 'collapsable',
    icon: 'mat_outline:article',
    permissions: [PermissionsEnum.GUIDES],
    children: [
      ADMIN_ITEMS_DATA[AdminItem.ROLES].navigation,
      ADMIN_ITEMS_DATA[AdminItem.REGIONS].navigation,
      ADMIN_ITEMS_DATA[AdminItem.DISTRICTS].navigation,
      ADMIN_ITEMS_DATA[AdminItem.AREAS].navigation,
      ADMIN_ITEMS_DATA[AdminItem.NEIGHBORHOODS].navigation,
      ADMIN_ITEMS_DATA[AdminItem.PLACE_TYPE].navigation,
      ADMIN_ITEMS_DATA[AdminItem.LOCATION_TYPE].navigation,
      ADMIN_ITEMS_DATA[AdminItem.ORIGIN_TYPE].navigation,
      ADMIN_ITEMS_DATA[AdminItem.FUEL_TYPE].navigation,
      ADMIN_ITEMS_DATA[AdminItem.TECHNIQUE_TYPE].navigation,
    ],
  },
];
