type logType = 'API' | 'ERROR';
export function logger(
  type: logType,
  start: number = Date.now(),
  url: string,
  params: any,
  response: any,
  message = ''
): void {
  const req_url = url.split('/');
  if (localStorage.getItem('LOG')) {
    /* eslint-disable no-console */
    if (type === 'API')
      console.groupCollapsed('%cAPI:', 'background-color: blue; color: white; paddding: 2px',   req_url[2].trim());
    else console.groupCollapsed('%cERROR:', 'background-color: red; color: white; paddding: 2px', req_url.join('/'));

    console.log('%cBase URL:', 'color: blue', req_url[2].trim());
    console.log('%cTIME:', 'color: green', Date.now() - start);
    console.log(`%cURL: ${req_url.slice(2).join('/')}`, 'color: pink');
    console.log(`%cPARAMS: `, 'color: orange', params);
    console.log('%cRESPONSE:', 'color: #16FF00', response);
    if (message) console.log('%cMESSAGE:', 'color: #16FF00', message);
    console.groupEnd();
  }
}
