import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, of, ReplaySubject } from 'rxjs';
import { NAVIGATION_DATA, RoleFuseNavigationItem } from './navigation-data';
import { IUser } from '@shared/features/user/user.interface';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  constructor(private _router: Router) {}

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get(user: IUser): Observable<Navigation> {
    const navigations = NAVIGATION_DATA.filter((nav: RoleFuseNavigationItem) => {
      if (nav.permissions) {
        for (const role of user.permissions) {
          if (nav.permissions.includes(role)) return true;
        }
        return false;
      }
      return true;
    })

    const adminPageNavigations = [];
    const otherNavigations = [];

    navigations.forEach((nav: RoleFuseNavigationItem) => {
      if (nav.id === 'admin') adminPageNavigations.push(nav);
      else otherNavigations.push(nav);
    });

    const navigation: Navigation = {
      compact: otherNavigations,
      default: otherNavigations,
      futuristic: otherNavigations,
      horizontal: otherNavigations,
      admin: adminPageNavigations,
    };
    this._navigation.next(navigation);

    return of(navigation);
  }
}
