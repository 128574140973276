import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from '@shared/features/user/user.service';
import { catchError, map, Observable, of, Subscription, switchMap, tap, timer } from 'rxjs';
import { HttpService } from '@shared/services/http.service';
import { Router } from '@angular/router';
import { IUser } from '@shared/features/user/user.interface';
import { AuthSession, AuthToken } from './models/auth.model';
import { Constants } from '@shared/constants/constants';
import { HttpClient } from '@angular/common/http';
import { PermissionsEnum } from '@shared/features/user/user-roles.interface';

@Injectable({ providedIn: 'root' })
export class AuthService extends HttpService {
  constructor(private _http: HttpClient) {
    super();
  }
  private _authenticated: boolean = false;
  private _userService = inject(UserService);
  private _router = inject(Router);

  refreshTokenTimeout?: Subscription;

  set accessToken(token: string) {
    localStorage.setItem(Constants.LOCAL_STORAGE_KEYS.accessToken, token);
  }

  set sessionId(session_id: string) {
    sessionStorage.setItem(Constants.LOCAL_STORAGE_KEYS.sessionId, session_id)
  }

  get sessionId() {
    return sessionStorage.getItem(Constants.LOCAL_STORAGE_KEYS.sessionId)
  }

  get accessToken(): string {
    return localStorage.getItem(Constants.LOCAL_STORAGE_KEYS.accessToken) ?? '';
  }

  set refreshToken(token: string) {
    localStorage.setItem(Constants.LOCAL_STORAGE_KEYS.refreshToken, token);
  }

  get refreshToken(): string {
    return localStorage.getItem(Constants.LOCAL_STORAGE_KEYS.refreshToken) ?? '';
  }

  get isTokenExpired() {
    return AuthUtils.isTokenExpired(this.accessToken);
  }

  createSession() {
    this.post<AuthSession>('sessions').subscribe((session) => {
      this.sessionId = session.id;
    });
  }

  forgotPassword(email: string): Observable<any> {
    return this.post('api/auth/forgot-password', email);
  }

  resetPassword(password: string): Observable<any> {
    return this.post('api/auth/reset-password', password);
  }

  signIn(credentials: { username: string; password: string }): Observable<IUser> {
    return this.post<AuthToken>('auth/login', credentials).pipe(
      switchMap(response => {
        if (response) {
          // TODO hozircha session id bilan ishlayabdi hamma apilar, auth ni backend bilan gaplashish kerak
          this.accessToken = response.session_id || response?.access_token_id;
          this.refreshToken = response?.refreshToken;

          this._authenticated = true;

          return this.get<IUser>('api/users/current_user').pipe(
            tap(user => {
              this._userService.user = user;
            })
          );
        }

        this._authenticated = false;
        return of(null);
      })
    );
  }

  getTokenByRefreshToken(): Observable<any> {
    return this.post<AuthToken>(Constants.LOCAL_STORAGE_KEYS.refreshToken, {
      refreshToken: this.refreshToken,
    }).pipe(
      catchError(error => {
        return of(false);
      }),
      tap(response => {
        if (response) {
          this.accessToken = response?.accessToken;
          this.refreshToken = response?.refreshToken;
          // this.startRefreshTokenTimer();
        }
      })
    );
  }

  signOut(): Observable<any> {
    this.stopRefreshTokenTimer();
    localStorage.removeItem(Constants.LOCAL_STORAGE_KEYS.accessToken);
    localStorage.removeItem(Constants.LOCAL_STORAGE_KEYS.refreshToken);

    this._authenticated = false;

    this._router.navigate(['/sign-in']).then();
    return of(true);
  }

  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this.post('api/auth/sign-up', user);
  }

  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true)
    }
    return this.getCurrentUser().pipe(map(user => !!user));
  }

  getCurrentUser(): Observable<IUser> {
    if (this._userService.user) return this._userService.user$;
    return this.get<IUser>(`api/users/current_user`, {}).pipe(
      tap(user => {
        this._authenticated = true;
        this._userService.user = user;
      }),
      catchError(() => this._router.navigate(['/sign-in']) && of(null))
    );
  }

  startRefreshTokenTimer() {
    // set a timeout to refresh the token a minute before it expires
    const offsetSeconds = 3600;
    const expires = AuthUtils.getTokenExpirationDate(this.accessToken);
    const timeout = expires.getTime() - Date.now() - offsetSeconds * 1000;

    this.refreshTokenTimeout = timer(timeout)
      .pipe(switchMap(() => this.getTokenByRefreshToken()))
      .subscribe();
  }

  private stopRefreshTokenTimer() {
    this.refreshTokenTimeout?.unsubscribe();
  }
}
