import { registerLocaleData } from '@angular/common';
import localeUzCyrl from '@angular/common/locales/uz-Cyrl';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import localeUzLatn from '@angular/common/locales/uz-Latn';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

registerLocaleData(localeUzCyrl);
registerLocaleData(localeRu);
registerLocaleData(localeUzLatn);
registerLocaleData(localeEn);

export const DATE_CONFIGS = [
  { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
  { provide: LOCALE_ID, useValue: 'uz-Cyrl' },
  { provide: LOCALE_ID, useValue: 'uz-Latn' },
  { provide: LOCALE_ID, useValue: 'ru-Ru' },
  {
    provide: DateAdapter,
    useClass: LuxonDateAdapter,
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: 'dd.MM.yyyy',
      },
      display: {
        dateInput: 'dd.MM.yyyy',
        monthYearLabel: 'MMMM yyyy',
        dateA11yLabel: 'dd.MM.yyyy',
        monthYearA11yLabel: 'MMMM yyyy',
      },
    },
  },
];
