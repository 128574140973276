import { Component, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { UserService } from '@shared/features/user/user.service';
import { PermissionsEnum } from '@shared/features/user/user-roles.interface';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <fuse-drawer
      fixed
      [mode]="'over'"
      [name]="'appDetailsDrawer'"
      [position]="'right'"
      #drawerDetails>
      <ng-container #viewContainerRef></ng-container>
    </fuse-drawer>
  `,
  standalone: true,
  imports: [RouterOutlet, FuseDrawerComponent],
  providers: [FuseConfirmationService],
})
export class AppComponent {
  static injector: Injector;
  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  entry: ViewContainerRef;
  @ViewChild('drawerDetails') drawerDetails: FuseDrawerComponent;

  constructor(
    private readonly injector: Injector,
    private userService: UserService,
    private _router: Router,
  ) {
    AppComponent.injector = injector;
    this.userService.user$.subscribe(async user => {
      if (window.location.pathname === '/' && user && !user.permissions.includes(PermissionsEnum.INCIDENTS_VIEW)) {
        if (user.permissions.includes(PermissionsEnum.ADMIN)) {
          await this._router.navigate(['admin', 'dashboard', 'user']);
          return;
        }
        if (user.permissions.includes(PermissionsEnum.GUIDES)) {
          await this._router.navigate(['admin', 'dashboard', 'roles']);
          return;
        }
        if (user.permissions.includes(PermissionsEnum.RESOURCES)) {
          await this._router.navigate(['admin', 'dashboard', 'brigades']);
          return;
        }
        return;
      }
    })
    // if (!$auth.isTokenExpired) $auth.startRefreshTokenTimer();
  }
}
