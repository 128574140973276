import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { hasPermissionGuard } from '@core/guards/has-permission.guard';
import { PermissionsEnum } from '@shared/features/user/user-roles.interface';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: '/incident/incidents' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/incident/incidents' },
  {
    path: 'constructor',
    loadChildren: () => import('./features/constructor-map/constructor/constructor.routes'),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
  },
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('./features/auth/sign-in-new/sign-in-new.routes'),
      },
    ],
  },

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('./features/auth/sign-out/sign-out.routes'),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'enterprise',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'incident',
        loadChildren: () => import('@features/incident/incident.routes'),
      },
      {
        path: 'statistics',
        loadChildren: () => import('./features/statistics/statistics.routes'),
      },
      {
        path: 'assign-brigade-at-work',
        loadComponent: () => import('./features/brigades/assign-brigade-at-work/assign-brigade-at-work.component').then(c => c.AssignBrigadeAtWorkComponent)
      }
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, hasPermissionGuard([PermissionsEnum.ADMIN, PermissionsEnum.GUIDES, PermissionsEnum.RESOURCES])],
    canActivateChild: [AuthGuard, hasPermissionGuard([PermissionsEnum.ADMIN, PermissionsEnum.GUIDES, PermissionsEnum.RESOURCES])],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    data: {
      layout: 'classic',
    },
    loadChildren: () => import('./features/admin/admin.routes'),
  },
  { path: '**', data: { layout: 'enterprise' }, pathMatch: 'full', redirectTo: '/' },
];
