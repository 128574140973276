import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@shared/features/user/user.service';
import { PermissionsEnum, UserRole } from '@shared/features/user/user-roles.interface';
import { tap } from 'rxjs';

export const hasPermissionGuard = (permissions: PermissionsEnum[]): CanActivateFn => {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);
    return userService.checkPermission(permissions).pipe(
      tap(hasPermission => {
        if (!hasPermission) router.parseUrl('/');
      })
    );
  };
};
