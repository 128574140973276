import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin, of, switchMap } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { IUser } from '@shared/features/user/user.interface';
import { NotificationsService } from '@shared/services/notifications.service';

export const initialDataResolver = () => {
  const navigationService = inject(NavigationService);
  const authService = inject(AuthService);
  // TODOL DISABLED SERVICE WORKER FOR PREVENTING STRONG CACHE
  // const notificationsService = inject(NotificationsService);

  // Fork join multiple API endpoint calls to wait all of them to finish
  return authService.getCurrentUser().pipe(
    switchMap((user: IUser) => {
      // TODOL DISABLED SERVICE WORKER FOR PREVENTING STRONG CACHE
      // notificationsService.subscribeToNotifications();
      return forkJoin([of(user), navigationService.get(user)]);
    })
  );
};
