import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, ReplaySubject, startWith, tap } from 'rxjs';
import { IArea } from '@shared/features/gist/interfaces/area.interface';
import { IncidentApiService } from '@features/incident/services/incident-api.service';
import { AlertService } from '@shared/services/alert.service';
import { IRequestParams } from '@shared/interfaces/request-params.interface';
import { UserService } from '@shared/features/user/user.service';
import { IUser } from '@shared/features/user/user.interface';
import { IName } from '@shared/interfaces/name.interface';
import { IncidentList, IncidentRequest } from '../interfaces/incident.interface';
import { IPageable } from '@shared/interfaces/pageable.interface';
import { Brigade } from '@features/incident/interfaces/brigade.interface';

@Injectable({
  providedIn: 'root',
})
export class IncidentService {
  constructor(
    private readonly incidentApiService: IncidentApiService,
    private readonly alertService: AlertService,
    private readonly userService: UserService
  ) {}

  listenOnUpdate = new ReplaySubject();
  // incident create page states
  areas$ = new BehaviorSubject<IArea[]>([]);
  neighborhoods$ = new BehaviorSubject<IArea[]>([]);

  branches$ = this.incidentApiService.getBranches().pipe(startWith<IName[]>([]));
  fuelTypes$ = this.incidentApiService.getFuelType().pipe(startWith<IName[]>([]));
  technique_types$ = this.incidentApiService.getTechniqueTypes().pipe(startWith<IName[]>([]));
  originTypes$ = this.incidentApiService.getOriginTypes().pipe(startWith<IName[]>([]));
  locationTypes$ = this.incidentApiService.getLocationTypes().pipe(startWith<IName[]>([]));
  placeTypes$ = this.incidentApiService.getPlaceTypes().pipe(startWith<IName[]>([]));
  brigades$ = this.incidentApiService.getBrigades().pipe(startWith<Brigade[]>([]));
  organizations$ = new BehaviorSubject<IName[]>([]);
  users$ = new BehaviorSubject<IName[]>([]);

  user: IUser;

  // incidents page states
  incidents$ = new BehaviorSubject<IPageable<IncidentList[]>>(null);

  loadUsersData(): void {
    this.user = this.userService.user;
  }

  getAreas(regionId: string, districtId: string): void {
    this.incidentApiService.getAreas(regionId, districtId).subscribe(res => this.areas$.next(res));
  }

  getUsers(): void {
    this.incidentApiService.getUsers().subscribe(res => this.users$.next(res));
  }

  getOrganizations(): void {
    this.incidentApiService.getOrganizations().subscribe(res => this.organizations$.next(res));
  }

  getNeighborhoods(area_id, district_id): void {
    this.incidentApiService.getNeighborhoods({ area_id, district_id }).subscribe(res => {
      if(Array.isArray(res) && res.length) {
        this.neighborhoods$.next(res)
      } else {
        this.neighborhoods$.next([])
      }
    })
  }

  onIncident(incident: IncidentRequest, id?: string) {
    if (id) {
      return this.incidentApiService.updateIncident(incident, id).pipe(
        filter(Boolean),
        tap(() => this.alertService.showSuccess('successfully.updated'))
      );
    } else {
      return this.incidentApiService.createIncident(incident).pipe(
        filter(Boolean),
        tap(() => this.alertService.showSuccess('successfully.created'))
      );
    }
  }

  loadIncidentsData(params: { incidents: IRequestParams }): void {
    this.getIncidents(params.incidents);
  }

  getIncidents(params: any): void {
    this.incidentApiService.getIncidents(params).subscribe(res => this.incidents$.next(res));
  }

  getIncident(id: string) {
    return this.incidentApiService.getIncident(id);
  }

  getIncidentCount() {
    return this.incidentApiService.getIncidentCounts();
  }

  getRegionsOrDistricts(region_id?: string) {
    return this.incidentApiService.getRegionsOrDistricts(region_id);
  }

  getBranches() {
    return this.incidentApiService.getBranches();
  }

  getBrigades(body?: any) {
    return this.incidentApiService.getBrigades(body);
  }

  getActiveBrigades(params?: any) {
    return this.incidentApiService.getActiveBrigades(params);
  }

  getSpecTechniques(url?: string, params?: any) {
    return this.incidentApiService.getSpecTechniques(url,params);
  }

  sendToBrigade(body) {
    return this.incidentApiService.sendToBrigade(body);
  }

  updateStatus(id, body) {
    return this.incidentApiService.updateStatus(id, body);
  }

  getBrigadeIncidents(brigade_id: string) {
    return this.incidentApiService.getBrigadeIncidents(brigade_id)
  }

  getIncidentTechniques(id: string) {
    return this.incidentApiService.getIncidentTechniques(id)
  }

  assignBrigade(body) {
    return this.incidentApiService.assignBrigade(body);
  }

  assignSpecTechnique(body) {
    return this.incidentApiService.assignSpecTechnique(body);
  }

  getBrigadeCoordinates(params) {
    return this.incidentApiService.brigadeCoordinates(params);
  }

  assignBrigadeAtWork(body) {
    return this.incidentApiService.assignBrigadeAtWork(body);
  }

  getIncidentHistory(id) {
    return this.incidentApiService.getIncidentHistory(id);
  }

  getTechniqueWithCoordinates() {
    return this.incidentApiService.getTechniquesWithCoordinates();
  }

  getBrigadeHistory<T>(params) {
    return this.incidentApiService.getBrigadeHistory<T>(params);
  }
}
